@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;800&display=swap");

@import "./common.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: $montserrat;
  -drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

input {
  border: none;
}

input[type="date"] {
  // display: block;
  width: 100% !important;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  // min-height: 1.2em;
}

input:focus {
  outline: none;
}

select:focus {
  outline: none;
}

textarea:focus {
  outline: none;
  // border: none;
}

textarea {
  outline: none;
  border: none;
}

body {
  // min-width: 780px;
  // width: 100% !important;
  // overflow: hidden;
  width: 100%;
}

html {
  background-color: whitesmoke;
}
