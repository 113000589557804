@import "./variables.scss";

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container-1320 {
  display: flex;
  padding: 0 15px;
  width: 100%;
  max-width: 1320px;
  height: 100%;
  overflow: hidden;
}

.outline {
  outline: 5px solid yellowgreen;
}

.btn {
  font-size: 1rem;
  color: white;
  border: none;
  height: 60px;
  font-weight: 500;
  background-color: $red;
  padding: 0 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
}
