@import "../../variables.scss";

.footer-section {
  height: auto;
  background-color: $red;
  position: relative;
}

.footer-abs-bottom-section {
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: $blue-dark;
  display: flex;
  justify-content: center;
}

.footer-copyright {
  padding: 5px 0;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  color: whitesmoke;
}

.footer-container {
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  @media screen and (max-width: 992px) {
    display: block;
  }
}

//
// LEFT DIV
//

.footer-left-div {
  padding: 30px 40px;
  outline-color: yellow;
  height: 100%;
  width: 40%;
  @media screen and (max-width: 992px) {
    padding: 5vw 3vw;
    width: 100%;
  }
}

.footer-right-div {
  outline-color: orange;
  height: 100%;
  width: 60%;
  @media screen and (max-width: 992px) {
    padding: 5vw 3vw;
    width: 100%;
  }
}

.footer-title {
  padding-left: 20px;
  margin-bottom: 30px;
  font-size: 2.9rem;
  font-weight: 800;
  color: white;
  @media screen and (max-width: 992px) {
    font-size: 2rem;
    padding-left: 2vw;
  }
}

.footer-contact {
  color: white;
  font-weight: 500;
  line-height: 1.8;
  margin-bottom: 10px;
}

.footer-name {
  padding-left: 10px;
  font-weight: 700;
  white-space: nowrap;
}

.footer-email {
  margin-top: 10px;
  padding-left: 40px;
  background-image: url("./email.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.footer-telephone {
  padding-left: 40px;
  background-image: url("./telephone.png");
  background-size: contain;
  background-repeat: no-repeat;
  white-space: nowrap;
}

.footer-address {
  display: inline;
}

.footer-address-option {
  // display: inline;
  white-space: nowrap;
  padding-left: 40px;
  background-image: url("./address.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.bbb {
  width: 250px;
}

.verify-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: auto;
  margin-bottom: 0;
  // justify-self: flex-end;
}

//
// RIGHT DIV
//
.footer-right-div {
  padding: 30px;
}

.footer-title-2 {
  padding-left: 20px;
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: 800;
  color: white;
}

.footer-form {
  position: relative;
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 10px;
  @media screen and (max-width: 992px) {
    display: block;
  }
}

.footer-grid-item {
  background-color: white;
  height: 60px;
  padding: 15px;
  @media screen and (max-width: 992px) {
    width: 100%;
    max-width: 600px;
    margin-bottom: 10px;
  }
}

.footer-grid-item-5 {
  grid-column: 1 / 3;
  // margin-bottom: 30px;
  height: 120px;
}
