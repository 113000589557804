$montserrat: "Montserrat", sans-serif;

$red: #ff1416;
$red-dark: #a40e0f;
$gold: #ffcb37;
$yellow-dark: #ffc800;
$blue: #0033ff;
$blue-dark: #08005a;

// Responsive Variables
$xxxl: "only screen and (min-width: 1600px) and (max-width: 1750px)";
$xxl: "only screen and (min-width: 1400px) and (max-width: 1599px)";
$xl: "only screen and (min-width: 1200px) and (max-width: 1399px)";
$lg: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md: "only screen and (min-width: 768px) and (max-width: 991px)";
$xs: "(max-width: 767px)";
$sm: "only screen and (min-width: 576px) and (max-width: 767px)";

$smart: "(max-width: 767px)";
