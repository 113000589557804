@import "../../variables.scss";

.second-section {
  position: relative;
  padding-top: 30px;
  height: auto;
  overflow: hidden;
}

.ss-box {
  position: absolute;
  max-width: 100vw;
  z-index: -1;
  left: -17%;
  bottom: 0;
}

.ss-right-abs-div {
  position: absolute;
  background-color: #e1e1e1;
  width: 33vw;
  height: 870px;
  right: -100px;
  z-index: -1;
  opacity: 0.3;
  @media screen and (max-width: 992px) {
    transform: translate(-5vw, -50px);
  }
}

.stripes {
  position: absolute;
  bottom: 0;
  opacity: 0.1;
}

.ss-container {
  overflow: visible;
  position: relative;
  padding: 30px 30px 0 30px;
  height: 100%;
  outline-color: palevioletred;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ss-title {
  text-align: center;
  line-height: 1;
  font-size: 2.9rem;
  font-weight: 800;
  margin-bottom: 50px;
  @media screen and (max-width: 992px) {
    font-size: 2rem;
  }
}

.ss-p {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.4;
  text-align: justify;
  @media screen and (max-width: 992px) {
    font-size: 1.1rem;
    line-height: 1.32;
  }
}

.btm-abs-div {
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: -1;
  height: 20%;
  background: $blue-dark;
}

.btm-abs-div2 {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  height: 35%;
  background: linear-gradient(160deg, transparent, rgb(168, 246, 85));
  outline-color: blue;
}

.ss-grid-wrapper {
  padding: 70px 0;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 30px;
  // flex-direction: column;
  // justify-content: space-around;
  // grid-template-columns: repeat(4, 1fr);
  // grid-auto-rows: minmax(40px, 40px);
  // gap: 20px;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 992px) {
    display: block;
  }
}

.ss-grid-item {
  padding: 15px;
  width: 40%;

  // height: calc(33% - 30px);
  background-color: rgba(255, 255, 255, 0.9);
  // border: 5px solid #08005a;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-bottom: 15px;
  }
}

.ss-grid-item-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.ss-grid-item-p {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.4;
  padding-left: 1rem;
  @media screen and (max-width: 992px) {
    font-size: 1.1rem;
    line-height: 1.32;
    text-align: justify;
  }
}
