.second-segment {
  overflow: hidden;
  position: relative;
}

.second-segment-background {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  opacity: 0.1;
}

.second-segment-background-img {
  height: 100%;
}
