.modal-section {
  top: 0;
  right: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(216, 216, 216, 0.8);
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  // overflow: scroll;
}

.modal-container {
  overflow: scroll;
  /* background-color: rgba(0, 0, 0, 0.5); */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.modal-container {
  overflow: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  width: 100%;
  height: 100%;
}

.modal-container-wrapper {
  overflow: hidden;
  position: relative;
  padding: 30px;
  padding-right: 0;
  margin: 15px;
  max-width: 900px;
  height: 100%;
  max-height: 500px;
  width: 90vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  // flex-direction: column;
  background-color: #ffffff;
  box-shadow: 20px 20px 40px rgba(0, 0, 0, 0.75);
  // overflow: scroll;
}
.modal-x-wrapper {
  transform: translateY(-30px);
  width: auto;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
.modal-x {
  // position: fixed;
  // top: 30px;
  // right: 30px;
  width: 42px;
  height: 42px;
  padding: 15px 15px 5px 5px;
  cursor: pointer;
}

.modal-back {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  opacity: 0.2;
  overflow: hidden;
  @media screen and (max-width: 720px) {
    opacity: 0.1;
  }
}

.modal-grid-wrapper {
  // width: 100%;
  max-width: 500px;
  display: grid;
  grid-auto-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  overflow: hidden;
}

.modal-title {
  width: 100%;
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: 800;
  line-height: 0.9;
  text-transform: uppercase;
}

.modal-btn {
  margin-top: 15px;
}

.modal-input {
  padding: 15px;
  font-size: 1.2rem;
  height: 60px;
  border: 5px solid black;
}
