.modal-section {
  top: 0;
  right: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(216, 216, 216, 0.8);
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
}
