.first-section {
  height: auto;
  padding-top: 30px;
}

.fs1-div {
  padding: 0 30px;
  position: relative;
  width: 100%;
  height: 100%;
}

.square-abs {
  width: min(45vw, 615px);
  height: 810px;
  position: absolute;
  background-color: #fff;
  //   opacity: 0.1;
  left: -4vw;
  top: -30px;
  z-index: -1;
}

.fs-img-div {
  float: left;
  margin-right: 30px;
  margin-top: 30px;
  margin-bottom: 20px;
  width: min(38vw, 615px);
  min-width: 30vh;
  height: auto;
  @media screen and (max-width: 992px) {
    margin-top: 0px;
    // width: 100%;
  }
}

.fs-img1 {
  width: 100%;
  //   height: 100%;
  border: 10px solid #f5f5f5;
  border-radius: 50%;
  // max-width: 30vw;

  @media screen and (max-width: 992px) {
    // width: 90%;
  }
}

.fs-text-div {
  width: auto;
}

.fs-title {
  //   //   width: min-content;
  margin-left: calc(min(38vw, 615px) + 40px);
  padding-top: 5px;
  font-weight: 800;
  //   padding-left: 50px;
  padding-bottom: 35px;
  font-size: 2.9rem;
  @media screen and (max-width: 992px) {
    font-size: 1.7rem;
    margin-left: 0;
    padding-bottom: 15px;
  }
}

.fs-p {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.4;
  text-align: justify;
  @media screen and (max-width: 992px) {
    font-size: 1.1rem;
    line-height: 1.32;
  }
}
