// .hero-section {
//     background-color: bisque;
//     height: 700px;
//     width: auto;
// }

.hero-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("./background.jpg");
  background-size: contain;
  aspect-ratio: 2.813;
  background-repeat: no-repeat;
  // background-color: bisque;
  height: auto;
  width: 100%;
}

.hero-1440-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1320px;
  @media screen and (max-width: 992px) {
    padding: 0 15px;
  }
}

.another-div {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border: 10px solid blue;
}

.left-div {
  position: absolute;
  left: 0;
  width: 75%;
  height: 100%;
  background-image: linear-gradient(
    63deg,
    whitesmoke 50%,
    transparent,
    transparent
  );
  background-repeat: no-repeat;
  // overflow:visible;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  // border: 10px solid purple;
}

.hero-title {
  // border: 10px solid purple;
  padding-left: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: baseline;
  z-index: 2;
  text-transform: uppercase;
  padding-bottom: 3vw;
  font-size: max(90px, 6.5vw);
  font-weight: 900;
  line-height: 1;
  color: #000;
  opacity: 0.87;
  white-space: nowrap;
  text-shadow: 10px 10px 10px rgb(164, 164, 164);
  // @media screen and (max-width: 768px) {
  //     font-size: 3rem;
  //     padding-bottom: 20px;
  // }
  // @media screen and (max-width: 576px) {
  //     font-size: 2rem;
  //     padding-bottom: 20px;
  // }
  // @media screen and (max-width: 1200px) {
  //     font-size: 2.5rem;
  // }
}
.hero-title-part {
  display: block;
  background-color: whitesmoke;
  padding: 0 3px;
  width: min-content;
  font-size: min(90px, 6.5vw);
}
