@import "../../variables.scss";

.projects-section {
  padding: 20px 0;
  position: relative;
  height: auto;
  background: transparent;
  overflow: hidden;
}

.projects-div {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  // overflow: hidden;
  // @media screen and (max-width: 992px) {
  //   width: 140px;
  //   aspect-ratio: 1;
  // }
}

.projects-title {
  font-size: 2.9rem;
  font-weight: 800;
  text-align: center;
  line-height: 1.1;
  @media screen and (max-width: 992px) {
    font-size: 2rem;
  }
}

.projects-grid-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 30px;
  grid-row-gap: 30px;
  padding-top: 40px;
  width: auto;
  @media screen and (max-width: 992px) {
    column-gap: 15px;
  }
  //   overflow: hidden;
}

.projects-grid-item-wrapper {
  min-width: 160px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projects-grid-item {
  width: auto;
  height: auto;
}

.projects-grid-item-bottom {
  transform: translateY(-25px);
  border-left: 3px solid black;
  width: 90%;
  height: auto;
  background-color: white;
  @media screen and (max-width: 992px) {
  }
}

.projects-grid-item-location {
  padding-left: 1vw;
  font-size: max(1vw, 12px);
  font-weight: 700;
}

.projects-grid-item-descr {
  padding-left: 1vw;
  font-size: max(1vw, 12px);
  font-weight: 400;
}

.projects-grid-item-img {
  width: 100%;
  height: 100%;
}

.stars-option {
  text-shadow: 2px 2px gray;

  display: inline;
  color: $red;
  font-weight: 900;
}

.stripes-option {
  text-shadow: 2px 2px gray;

  display: inline;
  color: $blue;
  font-weight: 900;
}
