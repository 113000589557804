@import "../../variables.scss";
.navbar-section {
  background-color: $blue-dark;
  height: 90px;
  max-width: 100vw;
}

.navbar-container {
  max-width: 100vw;
  padding: 10px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-left-div {
  // height: 60px;
  width: auto;
  @media screen and (max-width: 992px) {
    height: auto;
    max-width: 70%;
  }
}

.navbar-right-div {
  height: auto;
}

.logo {
  width: 100%;
  // height: 100%;
  padding: 2px 0;
}

.navbar-btn {
}
