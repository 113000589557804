@import "../../variables.scss";

.form-section {
  padding-top: 30px;
  // background: transparent;
  //   background: linear-gradient(whitesmoke, #e1e1e1);
}

.form-title {
  margin-bottom: 25px;
  font-size: 3.5rem;
  font-weight: 800;
  line-height: 0.8;
  @media screen and (max-width: 992px) {
    font-size: 2rem;
  }
}

.form-title-option {
  color: $red;
  font-size: 3.5rem;
  font-weight: 900;
  line-height: 0.8;
  margin-bottom: 50px;
  @media screen and (max-width: 992px) {
    font-size: 2rem;
    margin-bottom: 15px;
  }
}

.form-grid {
  position: relative;
  border-right: 10px solid #212121;
  width: 100%;
  // z-index: 1; //   aspect-ratio: 1.2;
  padding: 50px 30px;
  margin: 0 70px 50px 70px;
  background-color: white;
  @media screen and (max-width: 992px) {
    padding: 10px;
    padding-bottom: 15px;
    margin: 0 0 20px 0;
  }
}

.form-grid-left {
  width: 65%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 10px;
  @media screen and (max-width: 992px) {
    display: block;
    width: 100%;
    max-width: 700px;
  }
}

.form-grid-item {
  padding: 15px;
  width: 100%;
  height: 60px;
  border: 5px solid #cecece;
  font-weight: 100;
  font-size: 1.5rem;
  @media screen and (max-width: 992px) {
    font-size: 1rem;
    padding: 10px;
  }
}

.form-grid-item-title {
  font-size: 1rem;
}

// .form-grid-item-wrapper-last {
//   grid-column: 1 / 3;
// }

// .form-grid-item-last {
//   height: 120px;
// }

.form-btn {
  margin-top: 21px;
  align-self: baseline;
}

//
// FORM MESSAGE
//

.c2 {
  overflow: hidden;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: white;
  padding: 25px;
}

.tick {
  max-width: 200px;
  position: relative;
  margin-bottom: 15px;
}

.form-thanks {
  font-size: 1.5rem;
  text-align: left;
  margin-bottom: 20px;
  @media screen and (max-width: 992px) {
    font-size: 1.2rem;
  }
}

.form-back-link {
  cursor: pointer;
  text-decoration: underline;
}

.form-back {
  position: absolute;
  height: 100%;
  bottom: 0;
  right: 0;
  z-index: -1;
  opacity: 0.1;
  @media screen and (max-width: 720px) {
    right: -30%;
  }
}
