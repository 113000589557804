@import "../../variables.scss";

.qf-section {
  //   box-shadow: inset 0 -10px 10px 10px rgb(101, 27, 27);
  padding: 25px 30px;
  height: auto;
  background-color: $red;
  flex-direction: row !important;
  @media screen and (max-width: 992px) {
    padding: 20px 0;
  }
}

.qf-div {
  @media screen and (max-width: 992px) {
    margin: 0 15px;
    display: block;
    width: 100%;
  }
}

.qf-title {
  font-weight: 700;
  color: white;
  font-size: min(4vw, 2.5rem);
}

.qf-left-div {
  display: flex;
  align-items: center;
  width: 20%;
  @media screen and (max-width: 992px) {
    align-items: baseline;
    width: 100%;
  }
}

.qf-right-div {
  display: flex;
  width: 80%;
  height: auto;
  align-self: center;
  align-items: center;
  @media screen and (max-width: 992px) {
    width: 100%;
  }
}

.qf-form {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-around;
  //   justify-self: center;
  position: relative;
  width: 100%;
  height: auto;
  margin: 0;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(40px, 40px);
  gap: 20px;
  @media screen and (max-width: 992px) {
    // top: -15px;
    padding-top: 0px;
    // justify-items: baseline;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    grid-row-gap: 10px;
    grid-column-gap: 20px;
  }
  @media screen and (max-width: 992px) {
    // top: -15px;
    padding-top: 10px;
    // justify-items: baseline;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: auto;
    grid-row-gap: 10px;
    grid-column-gap: 20px;
    // padding-bottom: 20px;
  }
}

.qf-form-element {
  margin-top: 15px;
  height: 60px;
  padding: 15px;
  @media screen and (max-width: 992px) {
    margin-top: 0;
  }
}

.qf-btn {
  border: 4px solid white;
  cursor: pointer;
}

//
// QfMessageDiv
//

.qf-message-sbmtn-div {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ff1416;
  z-index: 2;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.qf-message-wrapper {
  //   outline: 5px solid greenyellow;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $red;
}

.qf-message-h3 {
  height: auto;
  // width: 80%;
  color: white;
}

.qf-message-p {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.qf-message-whitetick {
  max-height: 100px;
  padding-right: 20px;
}
