@import "../../variables.scss";

.third-section {
  position: relative;
}

.ts-abs-div {
  position: absolute;
  z-index: -1;
  top: 0;
  width: 100%;
  height: 50%;
  background-color: $blue-dark;
  border-bottom: 50px solid $red;
}

.ts-div {
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: baseline;
  align-items: center;
  padding-top: 60px;
}

.ts-title {
  // margin-left: 7rem;
  align-self: baseline;
  font-size: 4.6rem;
  line-height: 0.8;
  //   margin-bottom: 30px;
  color: $gold;
  font-weight: 800;
  word-wrap: nowrap;
  display: inline;
  @media screen and (max-width: 992px) {
    font-size: 3rem;
    line-height: 0.9;
    margin-left: 3rem;
  }
  @media screen and (max-width: 555px) {
    margin-left: 0rem;
  }
}

.ts-title-option {
  margin-left: 7rem;
  font-size: 4.6rem;
  line-height: 0.8;
  color: $red;
  font-weight: 800;
  word-wrap: nowrap;
  display: inline;
  @media screen and (max-width: 992px) {
    margin-left: 6rem;
    line-height: 0.9;
    font-size: 3rem;
  }
  @media screen and (max-width: 555px) {
    margin-left: 3rem;
  }
}

.ts-grid-container {
  padding-top: 40px;
  height: auto;
  width: auto;
  background-color: transparent;
  display: flex;
  justify-content: center;
}

.ts-grid-wrapper {
  // margin: auto auto;
  // width: auto;
  display: grid;
  flex-wrap: wrap;
  justify-content: baseline;
  grid-template-columns: repeat(3, 1fr);
  @media screen and (max-width: 1186px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 810px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.ts-grid-item-descr {
  color: #757575;
}

.ts-grid-item {
  // scale: 0.8;
  margin: 30px;
  padding-top: 40px;
  padding-bottom: 40px;
  min-width: 340px;
  max-width: 400px;
  background: linear-gradient(#e1e1e1, white);
  display: flex;
  flex-direction: column;
  align-items: center;
  //   border-bottom: 15px solid $red;
  @media screen and (max-width: 992px) {
    // margin: 10px;
  }
}

.ts-grid-img {
  //   position: absolute;
  //   top: -1px;
  //   width: 80%;
  scale: 0.8;
}

.ts-grid-img-back {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: white;
  width: 100%;
  max-width: min(180px, 75%);
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 20px;
}

// .ts-grid-wrapper-inner {
//   width: 100%;
//   height: 100%;
//   background-color: $gold;
//   opacity: 0.3;
// }

.ts-grid-item-price {
  font-size: 3.5rem;
}

.ts-grid-item-price-overlaid {
  text-decoration: line-through;
  margin-bottom: 20px;
}

.ts-grid-item-li {
  color: #757575;
  font-weight: 500;
  margin-bottom: 10px;
}

.ts-grid-item-li:last-of-type {
  margin-bottom: 20px;
}
